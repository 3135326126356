@media screen and (max-width: 480px) {
  .radio-control-group {
    display: flex;
    width: 100%;
  }
  .phone-form-container input[type='tel'] {
    width: 12% !important;
  }
}

.react-datepicker-popper {
  background: #ffffff !important;
  z-index: 99 !important;
}

.react-datepicker {
  background: #ffffff !important;
  z-index: 99;
  width: 100%;
  margin: 0 8px;
}

.react-datepicker__month-container,
.react-datepicker__time-container {
  float: none !important;
}

.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 100% !important;
}
.react-datepicker__time-container {
  border-left: 1px solid #aeaeae;
}
